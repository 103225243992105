<template>
    <div
        id="auscultation-campaign-dashboard"
        class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]"
    >
        <HeaderSlot
            :toBackButton="{ name: 'project', params: { id: workspaceId } }"
            :title="$cap(auscultationCampaign.name)"
            :subTitle="
                auscultationCampaign.description
                    ? auscultationCampaign.description
                    : 'Tableau de bord'
            "
            :isButtonHidden="!isDataLoaded"
            :isBackButtonHidden="isGuest"
        >
            <ButtonSlot
                v-if="
                    (false === isGuest &&
                        (auscultationCampaign.status === 'finishing' ||
                            auscultationCampaign.status === 'finished' ||
                            auscultationCampaign.status === 'archived') &&
                        $hasRight('campaigns.generateLink')) || $hasRight('users.isSuperAdmin')
                "
                @click="openGenerateLinkModale()"
                _icon="mdi-link-variant-plus"
                :_res="1550"
            >
                Générer un lien public
            </ButtonSlot>

            <EngineerQuestion
                v-if="!isGuest && false === $store.state.user.current.isInternal"
                :_res="1550"
            />

            <ButtonSlot
                :_to="{
                    name: mapComponentName,
                    params: { id: auscultationCampaign.id }
                }"
                :_res="1550"
                _icon="mdi-map-outline"
            >
                Accéder à la carte
            </ButtonSlot>
        </HeaderSlot>

        <div
            ref="printMe"
            class="message_container"
            v-if="dashboardDisplayedBool === false"
        >
            <span>Vos résultats seront bientôt disponibles</span>
        </div>

        <div
            class="tw-grow tw-overflow-y-auto tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-[10px] tw-pt-[10px]"
            v-else-if="isDataLoaded && dashboardDisplayedBool === true"
        >
            <!--<v-textarea
			class="tw-w-full tw-max-h-[94px] tw-pb-[10px]"
			label="Commentaire global"
			id="comment-textarea"
			outlined
			rows="2"
			color="grey"
			:disabled="!auscultationCampaign.globalComment && isCommentDisable"
			:class="[
				isCommentDisable
					? globalCommentInactiveClass
					: globalCommentActiveClass
			]"
			hide-details
			no-resize
			v-model="auscultationCampaign.globalComment"
			:readonly="isCommentDisable"
			>
				<template slot="append">
					<div
					class="tw-absolute tw-top-[50%] tw-right-[15px] tw-translate-y-[-50%]"
					v-if="$hasRight('campaigns.addCommentsToDashboard')"
					>
						<v-icon
						v-if="isCommentDisable"
						@click="enableModifyComment"
						>
							mdi-pen
						</v-icon>

						<v-icon
						v-if="!isCommentDisable"
						@click="modifyComment"
						>
							mdi-check-outline
						</v-icon>
					</div>
				</template>
			</v-textarea>-->

            <!-- ####### Tab principale ####### -->

            <div class="tw-w-full">
                <v-tabs
                    color="$brown"
                    v-model="tab"
                    hide-slider
                    class="all-border-bottom"
                >
                    <v-tab
                        v-for="category in categoriesList"
                        :key="category"
                        @click="reloadGraphs(category, false)"
                        active-class="activeTab"
                        class="border-tab"
                    >
                        {{ category }}
                    </v-tab>
                </v-tabs>
            </div>

            <!-- ####### Fin tab principale ####### -->

            <div
                class="tw-w-full tw-overflow-hidden tw-grow tw-border-[1px] tw-border-solid tw-border-[rgba(0,0,0,0.12)] tw-rounded-[4px]"
            >
                <v-tabs-items
                    v-model="tab"
                    class="tw-h-full"
                >
                    <!-- ####### Contenue de la tab principale choisie ####### -->
                    <v-tab-item
                        v-for="category in categoriesList"
                        :key="category"
                        class="tw-w-full tw-h-full"
                    >
                        <div
                            class="tw-w-full tw-flex tw-flex-col tw-gap-[5px] tw-items-start tw-h-full tw-overflow-hidden"
                        >
                            <!-- ####### Les filtres de la tab ####### -->
                            <div class="tw-flex tw-pl-[10px] tw-pr-[10px] tw-w-full tw-gap-[10px]">
                                <div
                                    v-for="filter in dynamicFilterList[category]"
                                    :key="filter.id"
                                >
                                    <div>
                                        <v-select
                                            class="background"
                                            multiple
                                            :items="allFilterData[category][filter.columnName]"
                                            :label="filter.name"
                                            item-value="value"
                                            item-text="label"
                                            v-model="filter.currentValue"
                                            @change="handleInput(filter, category);"
                                            clearable
                                            hide-details
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- ####### Fin des filtres de la tab ####### -->
                            <div
                                class="tw-w-full tw-justify-center tw-overflow-y-auto tw-p-[10px] tw-flex tw-flex-wrap tw-gap-[10px]"
                            >
                                <div
                                    class="tw-grow tw-shrink tw-outline-[1px] tw-outline tw-outline-[rgba(0,0,0,0.12)] tw-rounded-[4px] tw-p-[10px] tw-flex tw-flex-col tw-justify-between tw-items-center"
                                    :class="{
                                        'lg:tw-basis-[calc(50%-5px)] tw-basis-full': chart.width ? chart.width === 50 : chart.type === 'Pie' || (chart.direction === 'V' && chart.type === 'ColumnNBar'),
                                        'tw-basis-full': chart.width ? chart.width === 100 : chart.type === 'ColumnNBar' && chart.direction === 'H'
                                    }"
                                    outlined
                                    v-for="chart in allCharts[category]"
                                    :key="chart.id"
                                >
                                    <div
                                        v-show="isGenerationOK(chart) && (isLoadingCharts[chart.id] !== undefined && false === isLoadingCharts[chart.id])"
                                        :id="`chart_${chart.id}`"
                                        class="tw-w-full"
                                        :class="{
                                            'tw-h-[375px]': chart.type === 'Pie' || (chart.direction === 'V' && chart.type === 'ColumnNBar'),
                                            'tw-h-[450px]': (chart.type === 'ColumnNBar' && chart.direction === 'H') || chart.type === 'LineNArea'
                                        }"
                                    />

                                    <div
                                        v-show="isGenerationOK(chart) && (isLoadingCharts[chart.id] === undefined || true === isLoadingCharts[chart.id])"
                                        class="tw-w-full tw-h-full text-center"
                                    >
                                        <v-progress-circular
                                            :size="200"
                                            :width="10"
                                            color="#2c0703"
                                            indeterminate
                                        />
                                    </div>
                                    <div
                                        v-if="!isGenerationOK(chart)"
                                        class="tw-w-full"
                                        :class="{
                                            'tw-h-[375px]': chart.type === 'Pie' || (chart.direction === 'V' && chart.type === 'ColumnNBar'),
                                            'tw-h-[450px]': chart.type === 'ColumnNBar' && chart.direction === 'H',
                                        }"
                                    >
                                        <div
                                            v-text="`Graphique ${chart.name} : Erreur de la configuration - ${getErrorMessage(chart)}`"
                                            class="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full"
                                        />
                                    </div>

                                    <div
                                        class="Commentary tw-w-full"
                                        v-if="chart.comment && isCommentDisplay"
                                    >
                                        <span>COMMENTAIRE</span>

                                        <br />

                                        <p class="">
                                            {{ chart.comment }}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="tw-basis-full tw-flex tw-flex-wrap"
                                    v-for="statisticTable in statisticTables[category]"
                                    :key="statisticTable.id"
                                >
                                    <StatisticTable
                                        v-if="isGenerationOK(statisticTable) && !isLoadingStatisticTables[statisticTable.id]"
                                        :name="statisticTable.name"
                                        :headers="statisticTable.headers ? statisticTable.headers : []"
                                        :tableData="statisticTable.tableData ? statisticTable.tableData : []"
                                        :footer="`Linéaire ausculté : ${statisticTable.totalLinearLength} mètres`"
                                    />

                                    <div
                                        v-if="!isGenerationOK(statisticTable)"
                                        class="tw-flex tw-justify-center tw-items-center tw-w-full tw-outline-[1px] tw-outline tw-outline-[rgba(255,0,0,1)] tw-rounded-[4px] tw-p-[10px]"
                                        v-text="`Tableau des statistiques ${statisticTable.name} : Erreur de la configuration - ${getErrorMessage(statisticTable)}`"
                                    />

                                    <div
                                        v-if="isGenerationOK(statisticTable) && (isLoadingStatisticTables[statisticTable.id] === undefined || true === isLoadingStatisticTables[statisticTable.id])"
                                        class="tw-w-full tw-h-full text-center"
                                    >
                                        <v-progress-circular
                                            :size="200"
                                            :width="10"
                                            color="#2c0703"
                                            indeterminate
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-tab-item>
                    <!-- ####### Fin du contenu de la tab choisie ####### -->
                </v-tabs-items>
            </div>
        </div>

        <SiteDeactivated v-if="isSiteDeactivated" />

        <Modale
            v-if="isGenerateLinkModaleOpen"
            title="Partager la campagne"
            @close="closeGenerateLinkModale"
            @validate="closeGenerateLinkModale"
        >
            <div class="generateLinkInput">
                <div
                    class="justify-center mt-4"
                    style="width: 100%"
                >
                    <v-row class="pr-4 pl-4">
                        <v-text-field
                            dense
                            ref="generatedLink"
                            outlined
                            disabled
                            :value="url"
                            :loading="loadUrlGuest"
                            class="mr-4"
                        />

                        <MediumButtonSlot @click="copyTextToClipboard()">
                            Copier le lien
                        </MediumButtonSlot>
                    </v-row>
                </div>
            </div>

            <template slot="actions">
                <LowButtonSlot @click="closeGenerateLinkModale()">
                    Fermer
                </LowButtonSlot>
            </template>
        </Modale>
    </div>
</template>

<script>
import amchartUtils from '../../utils/amcharts.utils';
import StatisticTable from '../../components/StatisticTable/StatisticTable.vue';
import COLUMN_NAME_CONSTANT from '../../utils/statistic-table/columnname.const';
import CHART_TYPE_CONSTANT from '../../utils/dashboard/charttypes.const';
import GENERATION_STATUS from '../../utils/generationstatus.const';
import auth from '../../service/auth';
import EngineerQuestion from '../../components/popup/EngineerQuestion';
import SiteDeactivated from '../../components/campaign/SiteDeactivated.vue';

export default {
    name: 'AuscultationDashboard',
    components: {
        EngineerQuestion,
        StatisticTable,
        SiteDeactivated,
    },
    data () {
        return {
            url: '',
            isGenerateLinkModaleOpen: false,
            auscultationCampaign: {
                name: '',
                id: '',
                status: '',
                globalComment: '',
            },
            workspaceId: '',
            chart: {},
            tab: {},
            subTabs: [],
            statisticTables: {},
            isGuest: false,
            mapComponentName: 'auscultation-campaign-map',
            globalCommentActiveClass: 'cursor_text',
            globalCommentInactiveClass: 'cursor_default',
            isCommentDisable: true,
            dynamicFilterList: [],
            categoriesList: [],
            selectedCategory: null,
            allFilterData: [],
            allCharts: [],
            isFilterLoad: false,
            filters: {},
            isCommentDisplay: true,
            loadUrlGuest: true,
            isDataLoaded: false,
            isLoadingCharts: {},
            createdCharts: {},
            isLoadingStatisticTables: {},
            isSiteDeactivated: false,
            dashboardDisplayedBool: false,
        };
    },
    methods: {
        isGenerationOK (chart) {
            return chart.status === GENERATION_STATUS.GENERATION_FINISHED;
        },
        getErrorMessage (generatedObject) {
            if (generatedObject.status === GENERATION_STATUS.EMPTY) {
                return 'Veuillez regénérer avec ETL!';
            } else if (generatedObject.status === GENERATION_STATUS.ON_GENERATION) {
                return 'En cours de génération!';
            } else if (generatedObject.status === GENERATION_STATUS.ERROR) {
                return generatedObject.errorMessage + '!';
            }
            return 'Non définie';
        },
        async isDashboardDisplayed () {
            const guestToken = localStorage.getItem('guest_token');
            if (undefined === guestToken) return setTimeout(this.isDashboardDisplayed(), 200);
            const user = auth.decodeToken();

            this.dashboardDisplayedBool = null !== user
                ? (user.job === 'study_manager' &&
            [
            	'processing',
            	'engineering',
            	'finishing',
            	'finished',
            	'archived',
            ].indexOf(this.auscultationCampaign.status) !== -1) ||
            (user.job === 'engineer' && [
                'engineering', 'finishing', 'finished', 'archived',
            ].indexOf(this.auscultationCampaign.status) !== -1) ||
            (user.job === 'commercial' &&
              [
              	'finishing', 'finished', 'archived',
              ].indexOf(
              	this.auscultationCampaign.status,
              ) !== -1) ||
            (user.job === null &&
              ['finished', 'archived'].indexOf(
              	this.auscultationCampaign.status,
              ) !== -1)
                : null !== guestToken &&
            ['finished', 'archived'].indexOf(
            	this.auscultationCampaign.status,
            ) !== -1;
        },
        async reloadGraphs (category, forceReload) {
            this.selectedCategory = category;
            if (this.allCharts[category] === undefined) {
                this.isFilterLoad = false;
                this.getFiltersList();
                this.checkIfFilterLoaded();
            }
            if (forceReload) {
                await Promise.all(this.allCharts[this.selectedCategory]
                    .map(async (chart, key) => {
                        if (this.isGenerationOK(chart)) {
                            this.$set(this.isLoadingCharts, chart.id, true);
                            await this.getChartsData(chart.id, key);
                        }
                    }));
                this.createOrUpdateCharts();
                this.getStatisticTables();
            }
        },
        checkIfFilterLoaded () {
            let timeoutId = setTimeout(() => this.checkIfFilterLoaded(), 200);
            if (this.isFilterLoad) {
                this.getCharts();
                this.getStatisticTables();
                clearTimeout(timeoutId);
            }
        },
        getCharts () {
            this.$api.auscultationCampaigns
                .getCharts(this.$route.params.id, this.selectedCategory)
                .then(async data => {
                    this.allCharts[this.selectedCategory] = data;
                    this.$forceUpdate();
                    await Promise.all(this.allCharts[this.selectedCategory]
                        .map(async (chart, key) => {
                            if (this.isGenerationOK(chart)) {
                                this.$set(this.isLoadingCharts, chart.id, true);
                                await this.getChartsData(chart.id, key);
                            }
                        }));
                    this.createOrUpdateCharts();
                });
        },
        async getChartsData (chartId, index) {
            let indexLocal = index;
            await this.$api.auscultationCampaigns
                .getChartData(chartId, this.dynamicFilterList[this.selectedCategory])
                .then(data => {
                    if (this.allCharts[this.selectedCategory] === undefined) {
                        this.allCharts[this.selectedCategory] = {};
                    }
                    this.allCharts[this.selectedCategory][indexLocal].data = data.data;
                    this.allCharts[this.selectedCategory][indexLocal].colors =
            data.serieColors;
                    this.allCharts[this.selectedCategory][indexLocal].serieCount =
            data.serieCount;
                    this.allCharts[this.selectedCategory][indexLocal].serieKeys = data.serieKeys;
                })
                .catch(error => {
                    this.$set(this.isLoadingCharts, chartId, false);
                });
        },
        checkIfCommentDisplay () {
            this.isCommentDisplay = !this.dynamicFilterList[
                this.selectedCategory
            ].some(filter => filter.currentValue);
        },
        getCampaignCategories () {
            this.$api.auscultationCampaigns
                .getCategories(this.$route.params.id, true, false)
                .then(categories => {
                    categories.forEach((category, index) => {
                        this.categoriesList.push(category);
                        this.allFilterData[category] = {};

                        if (categories.length === index + 1) {
                            this.selectedCategory = this.categoriesList[0];
                            this.getFiltersList();
                            this.checkIfFilterLoaded();
                        }
                    });
                });
        },
        getFiltersList () {
            this.$api.mapLayers
                .getFiltersList(this.$route.params.id, this.selectedCategory, GENERATION_STATUS.GENERATION_FINISHED)
                .then(filters => {
                    this.dynamicFilterList[this.selectedCategory] = filters;
                    if (this.dynamicFilterList[this.selectedCategory].length === 0) {
                        this.getCharts();
                        this.getStatisticTables();
                    } else {
                        this.dynamicFilterList[this.selectedCategory].forEach(filter => {
                            this.getColumnFilter(filter);
                        });
                    }
                });
        },
        getFilterValues (filter) {
            this.$api.mapLayers.getFilterValueByUser(filter.id).then(data => {
                if (data.length > 0) {
                    this.dynamicFilterList[this.selectedCategory].find(
                        dynamicFilter => dynamicFilter.columnName === filter.columnName,
                    ).currentValue = data[0].valueExact;
                }
                this.isFilterLoad = true;
                this.checkIfCommentDisplay();
                this.$forceUpdate();
            });
        },
        updateFilters (category, excludedFilter = null) {
            let dynamicFilterList = this.dynamicFilterList[category].filter(
                dynamicFilter =>
                    dynamicFilter.id !=
          (null != excludedFilter ? excludedFilter.id : null),
            );
            dynamicFilterList.forEach(dynamicFilter => {
                this.$set(this.allFilterData[category], dynamicFilter.columnName, [dynamicFilter.currentValue]);
                this.getColumnFilter(dynamicFilter);
            });
        },
        getColumnFilter (filter) {
            this.$api.mapLayers
                .getColumnFilter(
                    this.$route.params.id,
                    this.selectedCategory,
                    filter.columnName,
                )
                .then(columnFilterDatas => {
                    if (this.allFilterData[this.selectedCategory] === undefined) {
                        this.allFilterData[this.selectedCategory] = {};
                    }

                    let filterValues = [];
                    for (let key in columnFilterDatas) {
                        filterValues.push({ 'value': key, 'label': columnFilterDatas[key] });
                    }
                    this.allFilterData[this.selectedCategory][filter.columnName] = filterValues;
                    this.getFilterValues(filter);
                });
        },
        async handleInput (filter, category, index = null) {
            clearTimeout(this.filterTimeoutId);
            // Définissez un nouveau délai pour déclencher l'action
            this.filterTimeoutId = setTimeout(async () => {
                await this.applyFilter(filter, category, index);
                this.updateFilters(category, filter);
            }, 1000);
        },
        async applyFilter (filter, category, index = null) {
            this.checkIfCommentDisplay();
            const userId = this.$store.state.user.current.userId;
            if (userId) {
                await this.$api.mapLayers.putNewFilter(filter.id, {
                    value: filter.currentValue,
                    userId: userId,
                });
            }
            if (index !== null) {
                if (index + 1 === this.dynamicFilterList[category].length) {
                    this.reloadGraphs(category, true);
                }
            } else {
                this.reloadGraphs(category, true);
            }
        },
        generateShareLink () {
            this.$api.campaigns
                .inviteGuestUser(this.$route.params.id)
                .then(guestUser => {
                    this.url =
            window.location.origin + '/share-link?token=' + guestUser.token;
                    this.loadUrlGuest = false;
                });
        },
        fallbackCopyTextToClipboard () {
            this.$refs.generatedLink.focus();
            this.$refs.generatedLink.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('unable to copy', err);
            }
        },
        copyTextToClipboard () {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard();
                return;
            }
            navigator.clipboard.writeText(this.url);
            this.$toast('success', 'Lien copié dans le presse papier !');
        },

        openGenerateLinkModale () {
            this.isGenerateLinkModaleOpen = true;
            this.generateShareLink();
        },
        closeGenerateLinkModale () {
            this.isGenerateLinkModaleOpen = false;
            this.loadUrlGuest = true;
        },
        updateChart (chart) {
            if (this.createdCharts[chart.id] !== undefined) {
                this.createdCharts[chart.id].data = chart.data;
            } else {
                throw new Error('Chart ' + chart.name + ' is not created yet !');
            }
        },
        async createChart (chart) {
            let createdChart = null;
            switch (chart.type) {
            case CHART_TYPE_CONSTANT.ColumnNBar.name:
                if (chart.subType === CHART_TYPE_CONSTANT.ColumnNBar.subTypes.ColumnNBarStacked.name) {
                    createdChart = await this.createColumnNBarStackedChart(
                        `chart_${chart.id}`,
                        chart.data,
                        chart.colors,
                        chart.serieKeys,
                        chart.name,
                        chart.direction,
                        chart.axisLabel,
                    );
                } else {
                    createdChart = await this.createColumnNBarSimpleChart(
                        `chart_${chart.id}`,
                        chart.data,
                        chart.colors,
                        chart.serieKeys,
                        chart.name,
                        chart.direction,
                        chart.axisLabel,
                        false,
                        true,
                        0,
                    );
                }
                break;
            case CHART_TYPE_CONSTANT.Pie.name:
                createdChart = await this.createPieSimpleChart(
                    'chart_' + chart.id,
                    chart.data,
                    chart.name,
                );
                break;
            case CHART_TYPE_CONSTANT.LineNArea.name:
                createdChart = this.createLineSimpleChart(
                    `chart_${chart.id}`,
                    chart.data,
                    chart.colors ?? null,
                    chart.serieKeys,
                    chart.name,
                    chart.direction,
                    chart.axisLabel,
                    false,
                    true,
                    0,
                );
                break;
            default:
                throw new Error('Chart type not existed !');
            }
            return createdChart;
        },
        async createOrUpdateCharts () {
            for (const chart of this.allCharts[this.selectedCategory]) {
                if (this.createdCharts[chart.id] !== undefined && this.createdCharts[chart.id] !== null) {
                    try {
                        this.updateChart(chart);
                        this.$set(this.isLoadingCharts, chart.id, false);
                    } catch (error) {
                        this.$set(this.isLoadingCharts, chart.id, false);
                        throw error;
                    }
                } else {
                    if (this.isGenerationOK(chart)) {
                        this.createChart(chart).then((createdChart) => {
                            this.$set(this.isLoadingCharts, chart.id, false);
                            this.$set(this.createdCharts, chart.id, createdChart);
                        }).catch((error) => {
                            this.$set(this.isLoadingCharts, chart.id, false);
                            throw error;
                        });
                    } else {

                    }
                }
            }
        },
        getStatisticTableData (statisticTable, index) {
            this.$api.auscultationCampaigns
                .getStatisticTableData(statisticTable.id, this.dynamicFilterList[this.selectedCategory])
                .then(data => {
                    if (data && data.name) {
                        statisticTable.name = data.name;
                        let tableDataTemp = [];
                        if (data.rows) {
                            Object.keys(data.rows).forEach(featureName => {
                                let featureDesc = data.rows[featureName];
                                let dataTableContent = {};
                                if (statisticTable.hasFeatureColumn) {
                                    dataTableContent['featureName'] = featureName;
                                }
                                if (statisticTable.hasUnitColumn) {
                                    dataTableContent['unitName'] = featureDesc.unit;
                                }
                                let featureData = featureDesc.data;
                                Object.keys(featureData).forEach(dataIndex => {
                                    if (statisticTable.hasDesignationColumn) {
                                        dataTableContent['designationName'] =
								featureData[dataIndex].name;
                                    }
                                    if (statisticTable.hasResultColumn) {
                                        dataTableContent['value'] =
								featureData[dataIndex].value;
                                    }
                                    if (statisticTable.hasFreqPercColumn) {
                                        dataTableContent['freqPercValue'] =
								featureData[dataIndex].p_f;
                                    }
                                    tableDataTemp.push({ ...dataTableContent });
                                });
                            });
                            this.statisticTables[this.selectedCategory][index]['tableData'] = tableDataTemp;
                            this.$set(this.isLoadingStatisticTables, statisticTable.id, false);
                        }
                    }
                });
        },
        /**
         * @description create a Pie chart
         * @param {string} ref - the HTML element using its id
         * @param {object} data - the data used for the chart
         * @param {string} title - the title of the chart
         * @returns Promise
         */
        createPieSimpleChart (ref, data, title) {
            return amchartUtils.createPieSimpleChart(ref, data, title);
        },
        /**
         * @description create a Pie chart
         * @param {string} ref - the HTML element using its id
         * @param {object} data - the data used for the chart
         * @param {string} title - the title of the chart
         * @returns XYChart
         */
        createLineSimpleChart (ref,
            data,
            colors,
            serieKeys,
            title,
            direction,
            text,
            withLegend,
            withXCategoryLabel,
            XCategoryLabelRotation,
        ) {
            return amchartUtils.createLineSimpleChart(ref,
                data,
                colors,
                serieKeys,
                title,
                direction,
                text,
                withLegend,
                withXCategoryLabel,
                XCategoryLabelRotation);
        },
        /**
         * @description create a horizontal XY chart
         * @param {string} ref - the HTML element using its id
         * @param {object} data - the data used for the chart
         * @param {object} colors - color for each channel
         * @param {string} title - the title of the chart
         * @param {string} text - the axis label of the chart
         * @returns Promise
         */
        createColumnNBarStackedChart (
            ref,
            data,
            colors,
            serieKeys,
            title,
            direction,
            text,
        ) {
            return amchartUtils.createColumnNBarStackedChart(
                ref,
                data,
                colors,
                serieKeys,
                title,
                direction,
                text,
                0,
            );
        },
        /**
         * @description create a vertical XY chart
         * @param {string} ref - the HTML element using its id
         * @param {object} data - the data used for the chart
         * @param {array} colors - the color list of each series
         * @param {string} title - the title of the chart
         * @param {string} direction - the chart direction
         * @param {string} axisLabel - the axis label of the chart
         * @param {bool} withLegend - display legend ?
         * @param {bool} withXCategoryLabel - display X category label ?
         * @returns Promise
         */
        createColumnNBarSimpleChart (
            ref,
            data,
            colors,
            serieKeys,
            title,
            direction,
            text,
            withLegend,
            withXCategoryLabel,
            XCategoryLabelRotation,
        ) {
            return amchartUtils.createColumnNBarSimpleChart(
                ref,
                data,
                colors,
                serieKeys,
                title,
                direction,
                text,
                withLegend,
                withXCategoryLabel,
                XCategoryLabelRotation,
            );
        },
        getStatisticTables () {
            this.$api.auscultationCampaigns
                .getStatisticTables(this.$route.params.id, this.selectedCategory)
                .then(data => {
                    this.statisticTables[this.selectedCategory] = data;
                    Object.keys(this.statisticTables[this.selectedCategory]).forEach(idx => {
                        this.statisticTables[this.selectedCategory][idx].headers = [];
                        this.statisticTables[this.selectedCategory][idx].hasFeatureColumn = false;
                        this.statisticTables[this.selectedCategory][idx].hasDesignationColumn = false;
                        this.statisticTables[this.selectedCategory][idx].hasUnitColumn = false;
                        this.statisticTables[this.selectedCategory][idx].hasResultColumn = false;
                        this.statisticTables[this.selectedCategory][idx].hasFreqPercColumn = false;

                        if (this.statisticTables[this.selectedCategory][idx][COLUMN_NAME_CONSTANT.FEATURE_NAME]) {
                            let header = {
                                text: this.statisticTables[this.selectedCategory][idx][
                                    COLUMN_NAME_CONSTANT.FEATURE_NAME
                                ],
                                align: 'center',
                                value: 'featureName',
                                sortable: false,
                            };
                            this.statisticTables[this.selectedCategory][idx].hasFeatureColumn = true;
                            this.statisticTables[this.selectedCategory][idx].headers.push(header);
                        }
                        if (this.statisticTables[this.selectedCategory][idx][COLUMN_NAME_CONSTANT.DESIGNATION_NAME]) {
                            let header = {
                                text: this.statisticTables[this.selectedCategory][idx][
                                    COLUMN_NAME_CONSTANT.DESIGNATION_NAME
                                ],
                                align: 'center',
                                value: 'designationName',
                                sortable: false,
                            };
                            this.statisticTables[this.selectedCategory][idx].hasDesignationColumn = true;
                            this.statisticTables[this.selectedCategory][idx].headers.push(header);
                        }
                        if (this.statisticTables[this.selectedCategory][idx][COLUMN_NAME_CONSTANT.UNIT_NAME]) {
              			let header = {
                                text: this.statisticTables[this.selectedCategory][idx][COLUMN_NAME_CONSTANT.UNIT_NAME],
                                align: 'center',
                                value: 'unitName',
                                sortable: false,
                            };
                            this.statisticTables[this.selectedCategory][idx].hasUnitColumn = true;
                            this.statisticTables[this.selectedCategory][idx].headers.push(header);
                        }
                        if (this.statisticTables[this.selectedCategory][idx][COLUMN_NAME_CONSTANT.RESULT_NAME]) {
              			let header = {
                                text: this.statisticTables[this.selectedCategory][idx][COLUMN_NAME_CONSTANT.RESULT_NAME],
                                align: 'center',
                                value: 'value',
                                sortable: false,
                            };
                            this.statisticTables[this.selectedCategory][idx].hasResultColumn = true;
                            this.statisticTables[this.selectedCategory][idx].headers.push(header);
                        }
                        if (this.statisticTables[this.selectedCategory][idx][COLUMN_NAME_CONSTANT.FREQ_PERC_NAME]) {
                            let header = {
                                text: this.statisticTables[this.selectedCategory][idx][COLUMN_NAME_CONSTANT.FREQ_PERC_NAME],
                                align: 'center',
                                value: 'freqPercValue',
                                sortable: false,
                            };
                            this.statisticTables[this.selectedCategory][idx].hasFreqPercColumn = true;
                            this.statisticTables[this.selectedCategory][idx].headers.push(header);
                        }
                        if (this.isGenerationOK(this.statisticTables[this.selectedCategory][idx])) {
                            this.$set(this.isLoadingStatisticTables, this.statisticTables[this.selectedCategory][idx].id, true);
                            this.getStatisticTableData(this.statisticTables[this.selectedCategory][idx], idx);
                        }
                    });
                });
        },

        enableModifyComment () {
            this.isCommentDisable = false;
        },
        async modifyComment () {
            await this.$api.campaigns.editComment(
                this.$route.params.id,
                this.auscultationCampaign.globalComment,
            );
            this.isCommentDisable = true;
        },
    },
    mounted () {
        if (!auth.isLogged()) {
            this.isGuest = auth.isLoggedGuest();
        }
        this.mapComponentName = this.isGuest && !auth.isLogged() ? 'auscultation-campaign-map-guest' : 'auscultation-campaign-map';

        this.$api.auscultationCampaigns
            .findById(this.$route.params.id)
            .then(async (campaign) => {
                this.auscultationCampaign = campaign;
                this.workspaceId = this.auscultationCampaign.workspace.id;
                this.isDataLoaded = true;
                await this.isDashboardDisplayed();
                if (this.dashboardDisplayedBool) {
                    this.getCampaignCategories();
                }
            })
            .catch(error => {
                if (error.response.status === 401 && error.response.data.code === 5) {
                    this.isSiteDeactivated = true;
                }
            });

    },
};
</script>

<style lang="scss" scoped>
#auscultation-campaign-dashboard {
  .edit_button {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
  .cursor_text {
    textarea {
      cursor: text;
    }
  }
  .cursor_default {
    textarea {
      cursor: default !important;
    }
  }
}

.charts_container {
  height: calc(100vh - 336px);
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  bottom: 16px !important;
  padding: 16px 0 0 8px;
  .statistic_tables_container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .v-card {
    border: 1;
    width: calc(50% - 20px);
    margin: 0 8px 20px;
    padding: 20px;
    background: #fafafa;
    height: fit-content;
    .ChartBox {
      height: 375px;
    }
    .Loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .v-card.horizontal {
    width: calc(100% - 23px) !important;
    .ChartBox {
      height: 450px !important;
    }
  }
  .Commentary {
    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.5px;
      color: rgba(0, 0, 0, 0.6);
    }
    p {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.87);
      margin: 0;
    }
  }
}
.message_container {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.generateLinkInput {
  display: flex;
  align-items: center;
  justify-content: space-around;

  & input {
    width: 100%;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
  }
}
.resetButton {
  margin-top: 14px !important;
  right: 0px;
}
.activeTab {
  background-color: #2b080344;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid black;
}

.border-tab {
  border-top: thin solid rgba(0, 0, 0, 0.12);
  border-right: thin solid rgba(0, 0, 0, 0.12);
  border-left: thin solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.all-border-bottom {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  bottom: 1px;
  line-height: 20px;
}

.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

::v-deep #comment-textarea {
  margin-bottom: 10px;
}
</style>
