<template>
    <div
        class="tw-h-full tw-w-[50%] tw-right-full tw-bg-[#f1f1f1] tw-flex tw-flex-col tw-z-[2]"
        ref="diapo"
    >
        <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            class="tw-grow-0 tw-items-center tw-flex tw-justify-center"
        >
            <v-tab
                v-if="Object.keys(photosGeoJson).length !== 0"
                active-class="activeTab"
            >
                Images
            </v-tab>

            <v-tab
                v-if="floatDocGeoJson.length !== 0"
                active-class="activeTab"
            >
                Documents
            </v-tab>
        </v-tabs>
        <v-tabs-items
            @mousewheel.prevent="wheelEvent"
            v-model="tab"
            class="tw-grow tw-overflow-y-auto tw-mt-[10px]"
        >
            <v-tab-item
                v-if="Object.keys(photosGeoJson).length !== 0"
                class="tw-h-full"
            >
                <div class="tw-w-full tw-relative tw-h-full tw-flex">
                    <ButtonSlot
                        @click="fullScreen"
                        :_icon="isFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'"
                        class="tw-absolute tw-top-[10px] tw-right-[10px] tw-z-[2]"
                    />

                    <WaitingSlot
                        class="tw-w-full tw-h-full"
                        v-if="currentImage === false"
                    />

                    <div
                        v-else
                        class="tw-w-full tw-h-full tw-pt-[24px] tw-pb-[24px] tw-center tw-gap-[10px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-[5px]"
                    >
                        <img
                            :src="currentImage"
                            @click="isBackImage && !isFront ? isFront = ! isFront : isBackImage ? dialog = true : dialog = true"
                            class="tw-transition-all"
                            :class="[(isGroundSurvey ? '' : 'img'), (isBackImage && isFront ? 'tw-w-full' : isBackImage ? 'tw-w-[50%]' : 'tw-w-full')]"
                        />

                        <img
                            v-if="backPhotosGeoJson !== null "
                            :src="currentBackImage"
                            @click="isFront ? isFront = !isFront : dialog = true"
                            class="tw-transition-all img"
                            :class="isFront ? 'tw-w-[50%]' : 'tw-w-full'"
                        />
                    </div>

                    <v-icon
                        class="tw-absolute tw-top-[50%] tw-right-[15px] tw-text-[35px] tw-text-[white] tw-bg-[rgba(0,0,0,0.35)] tw-rounded-full"
                        @click="queueInc"
                    >
                        mdi-chevron-right
                    </v-icon>

                    <v-icon
                        class="tw-absolute tw-top-[50%] tw-left-[15px] tw-text-[35px] tw-text-[white] tw-bg-[rgba(0,0,0,0.35)] tw-rounded-full"
                        @click="queueDec"
                    >
                        mdi-chevron-left
                    </v-icon>
                </div>
            </v-tab-item>
            <v-tab-item
                v-if="floatDocGeoJson.length !== 0"
                class="tw-h-full"
            >
                <div
                    class="tw-flex tw-flex-row tw-gap-[10px] tw-justify-center tw-pt-[10px] tw-flex-wrap tw-h-full tw-items-start"
                    v-if="selectedFile === null"
                >
                    <template v-if="openFolderSettingId === null">
                        <div
                            v-for="docSetting in docSettings"
                            :key="docSetting.id"
                            class="tw-flex tw-items-center tw-gap-[10px] tw-justify-center"
                            style="flex: 1 0 21%;"
                        >
                            <div
                                class="tw-flex tw-flex-col tw-items-center tw-relative"
                            >
                                <h3>{{ docSetting.name }}</h3>
                                <div class="tw-flex tw-flex-col tw-items-center tw-relative tw-w-[200px]">
                                    <div class="tw-mb-[10px] tw-flex tw-h-[200px] tw-rounded-[4px]">
                                        <v-icon
                                            size="200"
                                        >
                                            mdi-folder
                                        </v-icon>
                                    </div>
                                    <ButtonSlot
                                        @click="openFolderSettingId = docSetting.id"
                                        download="document.file"
                                        class="icon-button"
                                    >
                                        Accéder au dossier
                                    </ButtonSlot>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <v-btn
                            class="m_brown tw-absolute tw-left-[10px] tw-top-[10px]"
                            icon
                            @click="openFolderSettingId = null"
                        >
                            <v-icon large>
                                mdi-arrow-left-bold-circle
                            </v-icon>
                        </v-btn>

                        <template v-if="!isLoadedFiles">
                            <div
                                class="tw-flex tw-items-center tw-h-full tw-w-full tw-justify-center tw-p-[15px]"
                            >
                                <v-progress-circular
                                    indeterminate
                                    :size="300"
                                    color="dark-blue"
                                    :width="5"
                                >
                                    Chargement...
                                </v-progress-circular>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                v-for="file in fileSrc"
                                v-show="openFolderSettingId !== null"
                                :key="file.path"
                                class="tw-flex tw-items-center tw-gap-[10px] tw-justify-center tw-p-[15px]"
                                style="flex: 1 0 21%;"
                            >
                                <div
                                    class="tw-flex tw-flex-col tw-items-center tw-relative"
                                    v-if="getExtension(file).toLowerCase() == 'pdf'"
                                >
                                    <h3>{{ getName(file) }}</h3>
                                    <pdf
                                        class="tw-mb-[10px] tw-flex tw-w-[200px] tw-border-solid tw-rounded-[4px]"
                                        :src="file.path"
                                        :page="1"
                                    />
                                    <ButtonSlot
                                        @click="fullscreenFile(file)"
                                        _icon="mdi-fullscreen"
                                        class="tw-absolute tw-top-[40px] tw-right-[10px] tw-z-[2]"
                                        _tooltip="Agrandir le document"
                                    />
                                    <ButtonSlot
                                        @click="downloadFile(file)"
                                        download="document.file"
                                        class="icon-button"
                                    >
                                        Télécharger le pdf
                                    </ButtonSlot>
                                </div>
                                <div v-else-if="wordExtensions.includes(getExtension(file).toLowerCase()) || excelExtensions.includes(getExtension(file).toLowerCase())">
                                    <h3>{{ getName(file) }}</h3>
                                    <div class="tw-flex tw-flex-col tw-items-center tw-relative tw-w-[200px]">
                                        <div class="tw-mb-[10px] tw-flex tw-h-[200px] tw-border-solid tw-rounded-[4px]">
                                            <v-icon
                                                size="200"
                                                v-if="wordExtensions.includes(getExtension(file).toLowerCase())"
                                            >
                                                mdi-file-word
                                            </v-icon>
                                            <v-icon
                                                size="200"
                                                v-else-if="excelExtensions.includes(getExtension(file).toLowerCase())"
                                            >
                                                mdi-file-excel
                                            </v-icon>
                                        </div>
                                        <ButtonSlot
                                            @click="fullscreenFile(file)"
                                            _icon="mdi-fullscreen"
                                            class="tw-absolute tw-top-[10px] tw-right-[10px] tw-z-[2]"
                                        />
                                        <ButtonSlot
                                            @click="downloadFile(file)"
                                            download="document.file"
                                            class="icon-button"
                                        >
                                            Télécharger le document
                                        </ButtonSlot>
                                    </div>
                                </div>

                                <div v-else-if="getExtension(file).toLowerCase() === 'zip'">
                                    <div class="tw-flex tw-flex-col tw-items-center tw-relative tw-w-[200px]">
                                        <h3 class="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis tw-w-full">
                                            {{ getName(file) }}
                                        </h3>
                                        <div class="tw-mb-[10px] tw-flex tw-h-[200px] tw-border-solid tw-rounded-[4px]">
                                            <v-icon
                                                size="200"
                                            >
                                                mdi-zip-box
                                            </v-icon>
                                        </div>
                                        <ButtonSlot
                                            @click="downloadFile(file)"
                                            download="document.file"
                                            class="icon-button"
                                        >
                                            Télécharger le zip
                                        </ButtonSlot>
                                    </div>
                                </div>

                                <div v-else>
                                    <div class="tw-flex tw-flex-col tw-items-center tw-relative tw-w-[200px]">
                                        <h3 class="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis tw-w-full">
                                            {{ getName(file) }}
                                        </h3>
                                        <div class="tw-mb-[10px] tw-flex tw-h-[200px] tw-border-solid tw-rounded-[4px]">
                                            <v-icon
                                                size="200"
                                            >
                                                mdi-file
                                            </v-icon>
                                        </div>
                                        <ButtonSlot
                                            @click="downloadFile(file)"
                                            download="document.file"
                                            class="icon-button"
                                        >
                                            Télécharger le document
                                        </ButtonSlot>
                                    </div>
                                </div>
                            </div>
                            <v-pagination
                                v-model="pagePagination"
                                :length="getPages()"
                                rounded="circle"
                                class="tw-flex tw-fixed tw-items-center tw-bottom-[5px]"
                            />
                        </template>
                    </template>
                </div>

                <div
                    class="tw-w-full tw-justify-between tw-flex tw-flex-row"
                    v-else
                >
                    <ButtonSlot
                        @click="selectedFile = null"
                        class="tw-absolute tw-top-[10px] tw-left-[10px] tw-z-[2]"
                    >
                        Retour
                    </ButtonSlot>
                    <div
                        v-if="selectedFile && getExtension(selectedFile).toLowerCase() == 'pdf'"
                        class="tw-w-full tw-h-full"
                    >
                        <pdf
                            v-for="page in selectedFile.pages"
                            :key="page"
                            class="tw-mb-[10px] tw-w-full"
                            :src="selectedFile.path"
                            :page="page"
                        />
                    </div>
                    <template v-else-if="selectedFile && (wordExtensions.includes(getExtension(selectedFile).toLowerCase()) || excelExtensions.includes(getExtension(selectedFile).toLowerCase()))">
                        <div
                            class="tw-w-full tw-h-full"
                            v-html="selectedFile.htmlContent"
                        />
                    </template>
                </div>
            </v-tab-item>
        </v-tabs-items>

        <VDialog
            v-model="dialog"
            width="80rem"
            height="80rem"
        >
            <div class="tw-w-full tw-h-full tw-p-[10px] tw-bg-[white] tw-flex tw-flex-col tw-items-end tw-gap-[10px]">
                <img
                    :src="isFront ? currentImage : currentBackImage"
                    class="tw-w-full tw-h-full"
                />

                <ButtonSlot
                    _theme="light-gray"
                    @click="dialog = false"
                >
                    Fermer
                </ButtonSlot>
            </div>
        </VDialog>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import pdf from 'vue-pdf';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import FilePreviewModal from './FilePreviewModal.vue';
import mammoth from 'mammoth';
import { read, utils } from 'xlsx';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
    name: 'AuscultationDiaporamaMap',
    components: {
        pdf, FilePreviewModal,
    },
    props: {
        map: {
            type: Object,
        },
        photosGeoJson: {
            type: Object,
        },
        floatDocGeoJson: {
            type: Array,
        },
        isOpen: {
            type: Boolean,
        },
        indexPopup: {
            type: Number,
        },
        backPhotosGeoJson: {
            type: Object,
        },
        isGroundSurvey: {
            type: Boolean,
        },
        docSettings: {
            type: Array,
        },
    },

    data () {
        return {
            avatar: null,
            markers: [],

            queue: [],
            backQueue: [],
            loadedImage: {},
            backLoadedImage: {},
            index: false,

            dialog: false,
            isBackImage: false,
            isFront: true,
            isFullScreen: false,
            tab: 1,
            fileSrc: [],
            page: 1,
            numPages: 0,
            selectedFile: null,
            wordExtensions: [
                'doc',
                'docx',
            ],
            excelExtensions: [
                'xls',
                'xlsx',
            ],
            openFolderSettingId: null,
            isLoadedFiles: false,
            pagePagination: null,
            limit: 9,
            offset: 0,
        };
    },

    computed: {
        currentImage () {
            return this.loadedImage[this.queue[10]] || this.loadedImage[this.queue[0]];
        },

        currentBackImage () {
            return this.backLoadedImage[this.queue[10]] || this.backLoadedImage[this.queue[0]];
        },
    },

    watch: {
        floatDocGeoJson () {
            this.openFolderSettingId = null;
        },
        async selectedFile () {
            if (this.selectedFile !== null) {
                if (this.wordExtensions.includes(this.getExtension(this.selectedFile))) {
                    this.selectedFile.htmlContent = await this.getDocContent(this.selectedFile);
                } else if (this.excelExtensions.includes(this.getExtension(this.selectedFile))) {
                    this.selectedFile.htmlContent = await this.getExcelContent(this.selectedFile);
                }
            }
        },
        async openFolderSettingId () {
            if (this.openFolderSettingId !== null) {
                this.pagePagination = 1;
            } else {
                this.pagePagination = 0;
            }
        },
        async pagePagination () {
            this.isLoadedFiles = false;
            this.offset = (this.pagePagination - 1) * this.limit;
            let docs = [];
            let fileSrcs = [];
            // Collect all promises
            let floatDocs = this.floatDocGeoJson.filter(doc => doc.docSettingId === this.openFolderSettingId);

            const slicedArray = floatDocs.slice(this.offset, this.offset + this.limit);
            let promises = slicedArray.map(async doc => {
                let docResponse = await this.$api.documents.download(doc.path);
                docs.push({ data: docResponse, realPath: doc.path });
            });

            // Wait for all promises to resolve
            await Promise.all(promises);

            let filePromises = docs.map(async doc => {
                let arrayBuffer = await new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsArrayBuffer(doc.data);
                });

                let docFile = null;

                if (this.getExtension(doc).toLowerCase() === 'pdf') {
                    docFile = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
                }

                fileSrcs.push({
                    path: URL.createObjectURL(doc.data), pages: docFile?.numPages, realPath: doc.realPath, blob: doc.data,
                });
            });

            // Wait for all PDF processing to complete
            await Promise.all(filePromises);
            this.isLoadedFiles = true;
            this.fileSrc = fileSrcs;
        },
        isOpen () {
            if (this.isOpen === true) {
                if (this.backLoadedImage !== null) {
                    this.isBackImage = true;
                }
                this.index = this.indexPopup;
                this.initQueue();
                this.moveAvatar();
                this.$emit('diapoOpeningStateChanged', true);
            } else if (this.isOpen === false) {
                this.index = false;
                this.queue = [];
                this.backQueue = [];
                this.loadedImage = {};
                this.backLoadedImage = {};
                this.clearDecorations();
            }

            if (this.docSettings.length === 1) {
                this.openFolderSettingId = this.docSettings[0].id;
            }
        },
        indexPopup () {
            if (this.isOpen === true) {
                this.index = this.indexPopup;
                this.initQueue();
                this.moveAvatar();
            } else if (this.isOpen === false) {
                this.index = false;
                this.queue = [];
                this.backQueue = [];
                this.loadedImage = {};
                this.backLoadedImage = {};
                this.clearDecorations();
            }
        },
    },
    methods: {
        getPages () {
            return Math.ceil(this.floatDocGeoJson.filter(doc => doc.docSettingId === this.openFolderSettingId).length / this.limit);
        },
        async getDocContent (file) {
            try {
                const response = await fetch(file.path);
                const arrayBuffer = await response.arrayBuffer();
                const result = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer });
                return result.value;
            } catch (error) {
                console.error('Error converting file to HTML:', error);
            }
        },
        async getExcelContent (file) {
            try {
                const response = await fetch(file.path);
                const arrayBuffer = await response.arrayBuffer();
                const workbook = read(arrayBuffer, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const html = utils.sheet_to_html(worksheet);
                return html;
            } catch (error) {
                console.error('Error converting Excel file to HTML:', error);
                return '';
            }
        },
        getName (file) {
            const segments = file.realPath?.split('/');
  			return segments ? segments[segments.length - 1] : null;
        },
        getExtension (file) {
            const segments = file.realPath?.split('.');
  			return  segments ? segments[segments.length - 1] : null;
        },
        downloadFile (file) {
            const link = document.createElement('a');
            let fileName = this.getName(file);
            link.href = file.path;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        fullscreenFile (file) {
            this.selectedFile = file;
        },
        closeFileModal () {
            this.selectedFile = null;
        },
        moveAvatar () {
            if (this.photosGeoJson.features !== undefined) {
                let coords = this.photosGeoJson.features[this.index % this.photosGeoJson.features.length].geometry.coordinates;
                // Déplacement du camion en fonction des coordonnées de l'image
                if (this.avatar) {
                    this.avatar.remove();
                    this.avatar = null;
                }
                const icon = document.createElement('i');
                icon.className = 'large mdi mdi-truck avatar';
                icon.style.fontSize = 'xx-large';
                // Add markers to the map.
                this.avatar = new mapboxgl.Marker(icon).setLngLat(coords).addTo(this.map);
                this.$emit('updateModal', this.photosGeoJson.features[this.index]);
            }
        },

        clearDecorations () {
            for (const marker of this.markers) {
                marker.remove();
            }
            this.markers = [];
            if (this.avatar) this.avatar.remove();
        },

        initQueue () {
            if ((this.photosGeoJson?.features ?? []).length === 0) {
                return;
            }

            let queue = [];
            let backQueue = [];
            let images = [];
            for (let index = 0; index < Math.min(21, this.photosGeoJson.features.length); index++) {
                let i = (this.index - 2 + index) % this.photosGeoJson.features.length;
                queue.push(i);
                if (this.backPhotosGeoJson !== null) backQueue.push(i);
                images.push((async () => {
                    this.loadedImage[i] = await this.loadImage(i);
                    this.loadedImage = { ...this.loadedImage };
                    if (this.backPhotosGeoJson !== null) {
                        this.backLoadedImage[i] = await this.loadBackImage(i);
                        this.backLoadedImage = { ...this.backLoadedImage };
                    }
                })());

                this.queue = queue;
                if (this.backPhotosGeoJson !== null) this.backQueue = backQueue;
            }
        },

        async queueInc () {
            this.index++;
            let index = (this.index + 10) % this.photosGeoJson.features.length;
            let shiftedIndex = this.queue.shift();
            this.queue.push(index);
            this.moveAvatar();
            this.$forceUpdate();
            if (this.queue.indexOf(shiftedIndex) === -1) delete this.loadedImage[shiftedIndex];
            this.loadedImage[index] = await this.loadImage(index);
            this.loadedImage = { ...this.loadedImage };

            if (this.backPhotosGeoJson !== null) {
                this.backQueue.push(index);
                if (this.backQueue.indexOf(shiftedIndex) === -1) delete this.backLoadedImage[shiftedIndex];
                this.backLoadedImage[index] = await this.loadBackImage(index);
                this.backLoadedImage = { ...this.backLoadedImage };
            }
        },

        async queueDec () {
            this.index--;
            let index = (this.index - 10) % this.photosGeoJson.features.length;
            let popedIndex = this.queue.pop();
            this.queue.unshift(index);
            this.moveAvatar();
            this.$forceUpdate();
            if (this.queue.indexOf(popedIndex) === -1) delete this.loadedImage[popedIndex];
            this.loadedImage[index] = await this.loadImage(index);
            this.loadedImage = { ...this.loadedImage };
            if (this.backPhotosGeoJson !== null) {
                this.queue.unshift(index);
                if (this.backQueue.indexOf(popedIndex) === -1) delete this.backLoadedImage[popedIndex];
                this.backLoadedImage[index] = await this.loadBackImage(index);
                this.backLoadedImage = { ...this.backLoadedImage };
            }
        },

        async loadImage (index) {
            let { data } = await this.$axios.get(
                'files/' + this.photosGeoJson.features[index].properties.path,
                {
                    responseType: 'blob',
                    showLoader: false,
                },
            );
            return URL.createObjectURL(new Blob([data]));
        },

        async loadBackImage (index) {
            let { data } = await this.$axios.get(
                'files/' + this.backPhotosGeoJson.features[index].properties.path,
                {
                    responseType: 'blob',
                    showLoader: false,
                },
            );
            return URL.createObjectURL(new Blob([data]));
        },
        wheelEvent (event) {
            if (event.deltaY < 0) {
                this.queueInc();
            } else if (event.deltaY > 0) {
                this.queueDec();
            }
        },

        fullScreen () {
            if (window.document.fullscreenElement)window.document.exitFullscreen();
            else this.$refs.diapo.requestFullscreen();
        },
        changeFullScreen () {
            this.isFullScreen = !!window.document.fullscreenElement;
        },
    },
    mounted () {
        document.addEventListener('fullscreenchange', this.changeFullScreen);
    },
    unmounted () {
        document.removeEventListener('fullscreenchange', this.changeFullScreen);
    },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables.scss";
.diaporama_map {
  background-color: #f1f1f1;
  height: 100%;
  display: flex;
  align-items: center;
}
.avatar {
  color: $brown;
}
.mdi-circle {
  color: #412420 !important;
}
.v-btn--active {
  color: #412420 !important;
  background-color: rgba(65, 36, 36, 0.3) !important;
}
.v-window__container{
	transition-duration: 0;
	display: inherit !important;
	height: 100% !important;
}
.img {
	aspect-ratio: 16/9;
}
</style>
